@media only screen and (max-width: 400px) and (min-width: 300px) {
    .ExploreCard {
        display: flex;
        flex-direction: column;
        max-width: 95% !important;

        img {
            width: 100%;
        }

        &-top {
            margin-top: 20px;

            .btn-default {
                width: 200px !important;
                padding: 15px 10px;
            }
        }

        &-title {
            font-size: 18px;
        }
    }
}

@media only screen and (max-width: 560px) and (min-width: 400px) {
    .ExploreCard {
        display: flex;
        flex-direction: column;
        max-width: 95% !important;

        img {
            width: 100%;
        }

        &-top {
            margin-top: 20px;

            .btn-default {
                width: 200px !important;
                padding: 15px 10px;
            }
        }

        &-title {
            font-size: 18px;
        }
    }
}

@media only screen and (max-width: 575px) and (min-width: 560px) {
    .ExploreCard {

        display: flex;
        flex-direction: column;
        max-width: 95% !important;

        img {
            width: 100%;
        }

        &-top {
            margin-top: 20px;

            .btn-default {
                width: 200px !important;
                padding: 15px 10px;
            }
        }

        &-title {
            font-size: 18px;
        }
    }
}

@media only screen and (max-width: 700px) and (min-width: 575px) {
    .ExploreCard {
        display: flex;
        flex-direction: column;
        max-width: 95% !important;

        img {
            width: 100%;
        }

        &-top {
            margin-top: 20px;

            .btn-default {
                width: 200px !important;
                padding: 15px 10px;
            }
        }

        &-title {
            font-size: 18px;
        }
    }
}

@media only screen and (max-width: 768px) and (min-width: 700px) {
    .ExploreCard {
        display: flex;
        flex-direction: column;
        max-width: 90% !important;

        img {
            width: 100%;
        }

        &-top {
            margin-top: 20px;

            .btn-default {
                width: 200px !important;
                padding: 15px 10px;
            }
        }

        &-title {
            font-size: 18px;
        }
    }
}

@media only screen and (max-width: 800px) and (min-width: 768px) {
    .ExploreCard {
        img {
            width: 45%;
        }

        &-top {
            .btn-default {
                width: 200px !important;
                padding: 15px 10px;
            }
        }

        &-title {
            font-size: 18px;
        }
    }
}

@media only screen and (max-width: 850px) and (min-width: 800px) {
    .ExploreCard {

        img {
            width: 45%;
        }

        &-top {
            .btn-default {
                width: 200px !important;
                padding: 15px 10px;
            }
        }

        &-title {
            font-size: 18px;
        }
    }
}

@media only screen and (max-width: 900px) and (min-width: 850px) {
    .ExploreCard {

        img {
            width: 40%;
        }

        &-top {
            .btn-default {
                width: 200px !important;
                padding: 15px 0;
            }
        }
    }
}

@media only screen and (max-width: 1580px) and (min-width: 900px) {
    .ExploreCard {

        img {
            width: 40%;
        }

        &-top {
            .btn-default {
                width: 200px !important;
                padding: 15px 0;
            }
        }
    }
}