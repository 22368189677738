@media only screen and (max-width: 1026px) {
    .Header {
        z-index: 10;

        .logo {
            width: 100px;
        }

        &-link,
        &-lang {
            font-size: 12px;
        }
    }
}

@media only screen and (max-width: 932px) {
    .Header {
        z-index: 10;
        &-langs {
            display: none;
        }
    
        &-links,
        &-lang {
            display: none;
        }

        .btn-burger {
            display: block;
        }
    }
}

@media only screen and (max-width: 500px) {
    .Header {
        width: 100%;
        z-index: 10;

        &-links {
            display: none;
        }

        &-langs {
            display: none;
        }
    }
}