.VisaSupport {
    .swiper {
        height: 580px;
        padding: 50px 0;

        .swiper-container {
            width: 100%;
            height: 100%;
        }

        .swiper-wrapper {
            max-width: 100%;
            margin: 0 auto;
            display: flex;
            align-items: flex-start;

            .swiper-slide {
                text-align: center;
                max-width: 100% !important;
                padding: 0 20px;
                font-size: 18px;
                background: #fff;

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    .Card {
        img {
            width: 90%;
            height: 100%;
        }

        &-body {
            text-align: left;
            top: -180px;
            right: -180px;
        }
    }
}
