@media only screen and (max-width: 400px) and (min-width: 300px) {
    .Locations {
        .MainTitle {
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &-title {
                width: 100%;
                text-align: center !important;
                margin: 0;
            }

            &-text {
                width: 80% !important;
                text-align: center !important;
                margin: 0;
            }
        }
    }
}

@media only screen and (max-width: 560px) and (min-width: 400px) {
    .Locations {
        .MainTitle {
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &-title {
                width: 100%;
                text-align: center !important;
                margin: 0;
            }

            &-text {
                width: 80% !important;
                text-align: center !important;
                margin: 0;
            }
        }
    }
}

@media only screen and (max-width: 575px) and (min-width: 560px) {
    .Locations {
        .MainTitle {
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &-title {
                width: 100%;
                text-align: center !important;
                margin: 0;
            }

            &-text {
                width: 80% !important;
                text-align: center !important;
                margin: 0;
            }
        }
    }
}

@media only screen and (max-width: 700px) and (min-width: 575px) {
    .Locations {
        .MainTitle {
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &-title {
                width: 100%;
                text-align: center !important;
                margin: 0;
            }

            &-text {
                width: 80% !important;
                text-align: center !important;
                margin: 0;
            }
        }
    }
}

@media only screen and (max-width: 768px) and (min-width: 700px) {
    .Locations {
        .MainTitle {
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &-title {
                width: 100%;
                text-align: center !important;
                margin: 0;
            }

            &-text {
                width: 80% !important;
                text-align: center !important;
                margin: 0;
            }
        }
    }
}

@media only screen and (max-width: 850px) and (min-width: 800px) {
    .Locations {
        .MainTitle {
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &-title {
                width: 100%;
                text-align: center !important;
                margin: 0;
            }

            &-text {
                width: 80% !important;
                text-align: center !important;
                margin: 0;
            }
        }
    }
}

@media only screen and (max-width: 900px) and (min-width: 850px) {
    .Locations {
        .MainTitle {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &-title {
                width: 100%;
                text-align: center !important;
                margin: 0;
            }

            &-text {
                width: 80% !important;
                text-align: center !important;
                margin: 0;
            }
        }
    }
}

@media only screen and (max-width: 1580px) and (min-width: 900px) {
    .Locations {
        .MainTitle {
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &-title {
                width: 100%;
                text-align: center !important;
                margin: 0;
            }

            &-text {
                width: 80% !important;
                text-align: center !important;
                margin: 0;
            }
        }
    }
}