@media only screen and (max-width: 400px) and (min-width: 300px) {
    .ContactCard {
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 560px) and (min-width: 400px) {
    .ContactCard {
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 575px) and (min-width: 560px) {
    .ContactCard {
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 700px) and (min-width: 575px) {
    .ContactCard {
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 768px) and (min-width: 700px) {
    .ContactCard {
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 800px) and (min-width: 768px) {
    .ContactCard {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;

        &-title {
            font-size: 15px;
            text-align: center;
            // border: 1px solid red;
        }

        &-text {
            font-size: 13px;
            text-align: center;
        }
    }
}

@media only screen and (max-width: 850px) and (min-width: 800px) {
    .ContactCard {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0;

        &-title {
            font-size: 15px;
            text-align: center;
        }

        &-text {
            font-size: 13px;
            text-align: center;
        }
    }
}

@media only screen and (max-width: 900px) and (min-width: 850px) {
    .ContactCard {
        display: flex;
        flex-direction: column;
        align-items: center;

        &-title {
            font-size: 15px;
            text-align: center;
        }

        &-text {
            font-size: 13px;
            text-align: center;
        }
    }
}

@media only screen and (max-width: 1580px) and (min-width: 900px) {}

.ContactCard {
    display: flex;
    flex-direction: column;
    align-items: center;


    &-title {
        font-size: 15px;
        text-align: center;
    }

    &-text {
        font-size: 13px;
        text-align: center;
    }
}