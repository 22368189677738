.Prices {
    margin-bottom: 100px !important;

    &-cards {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .swiper {
        height: 600px;
    }
}