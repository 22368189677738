.FormSection {
    width: 100%;
    background-color: #135D66;

    &-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 50px 0;
        height: 600px;
    }

    &-map {
        height: 475px;
        border-radius: 20px;
        width: 59%;
    }

    &-title {
        font-size: 30px;
        text-align: center;
        margin-bottom: 50px;
        color: #2a2a2a;
        font-weight: 700;

        .blueText {
            color: #22b3c1;
        }
    }

    label {
        font-size: 15px;
        color: #afafaf;
    }

    &-input {
        width: 100%;
        height: 46px;
        background-color: transparent;
        border-radius: 23px;
        border: 1px solid #e0e0e0;
        padding: 0px 20px;
        margin-bottom: 30px;

        &:focus {
            border: none;
            outline: none;
            box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
            background-color: transparent;
            padding: 10px 20px;
        }
    }

    .btn-default {
        width: 100%;
        color: #fff;
    }

    &-info {
        width: 39%;
        padding: 30px;
        border-radius: 20px;
        background-color: #fff;
    }

    &-call {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 30px;
    }

    &-callChild {
        width: 48%;
        height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 23px;
    }

    &-numberName {
        font-size: 13px;
        font-weight: 700;
        margin-top: 15px;
    }

    &-number {
        font-size: 13px !important;
        color: #135D66;
    }
}