ul {
    margin: 0px;
    padding: 0px;
}

.footer-section {
    background: #151414;
    position: relative;
}

.footer-cta {
    border-bottom: 1px solid #373636;
}

.single-cta i {
    color: #3B667D;
    font-size: 30px;
    float: left;
    margin-top: 8px;
}

.cta-text {
    padding-left: 15px;
    display: inline-block;
}

.cta-text h4 {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 2px;
}

.cta-text span {
    color: #757575;
    font-size: 15px;
}

.footer-content {
    display: flex;
    align-items: flex-start;
    position: relative;
    z-index: 2;
}

.footer-pattern img {
    position: absolute;
    top: 0;
    left: 0;
    height: 330px;
    background-size: cover;
    background-position: 100% 100%;
}

.footer-logo {
    margin-bottom: 30px;
}

.footer-logo img {
    max-width: 200px;

}

.footer-text p {
    margin-bottom: 14px;
    font-size: 14px;
    color: #7e7e7e;
    line-height: 28px;
}

.footer-social-icon span {
    color: #fff;
    display: block;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0 !important;
    }
}

.footer-social-icon svg {
    height: 30px;
    width: 30px;
}

.facebook-bg {
    background: #3B5998;
}

.twitter-bg {
    background: #55ACEE;
}

.google-bg {
    background: #3B667D;
}

.footer-widget-heading h3 {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 40px;
    position: relative;
}

.footer-widget-heading h3::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -15px;
    height: 2px;
    width: 50px;
    background: #3B667D;
}

.footer-widget ul li {
    display: inline-block;
    // float: left;
    width: 50%;
    margin-bottom: 12px;
}

.footer-widget ul li a:hover {
    color: #3B667D;
}

.footer-widget ul li a {
    color: #878787;
    text-transform: capitalize;
}

.subscribe-form {
    position: relative;
    overflow: hidden;
}

.subscribe-form input {
    width: 100%;
    padding: 14px 28px;
    background: #2E2E2E;
    color: #fff;
    outline: none;
    border: none;
}

.subscribe-form button {
    position: absolute;
    right: 0;
    background: #3B667D;
    padding: 13px 20px;
    top: 0;
    outline: none;
    border: none;
}

.subscribe-form button svg {
    width: 25px;
    height: 25px;
    color: #fff;
    // font-size: 28px;
    // transform: rotate(-6deg);
}

.copyright-area {
    background: #202020;
    padding: 50px 0 80px 0;
}

.copyright-text p {
    width: 90%;
    margin: 0;
    font-size: 14px;
    color: #878787;
}

.copyright-text p a {
    color: #3B667D;
}

.footer-menu li {
    display: inline-block;
    margin-left: 20px;
    line-height: 40px;
}

.footer-menu li:hover a {
    color: #3B667D;
}

.footer-menu li a {
    font-size: 14px;
    color: #878787;
}

.Header-lang {
    display: inline-block;
    padding: 15px;
}

@media only screen and (min-width: 300px)  and (max-width: 576px) {
    .mb-xs-4 {
        margin-bottom: 35px;
    }
}