.MainTitle {
    width: 100%;
    text-align: center;
    margin: 80px 0;

    &-title {
        font-size: 30px;
        font-weight: 700;
        line-height: 36px;
        text-align: center;
        color: #2A2A2A;
        margin-bottom: 20px;
    }
    
    &-text {
        width: 60%;
        margin: auto;
        font-size: 15px;
        font-weight: 400;
        line-height: 30px;
        text-align: center;
        color: #AFAFAF;
        text-align: center;
        margin-bottom: 40px;
    }
}