@media only screen and (max-width: 400px) and (min-width: 300px) {
    .MainTitle {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        text-align: center;

        &-title {
            font-size: 25px;
            width: 85%;
            text-align: center;
        }

        &-text {
            width: 95%;
            font-size: 15px;
            text-align: center !important;
        }
    }
}

@media only screen and (max-width: 560px) and (min-width: 400px) {
    .MainTitle {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        text-align: center;

        &-title {
            font-size: 25px;
            width: 85%;
            text-align: center;
        }

        &-text {
            width: 95%;
            font-size: 15px;
            text-align: center !important;
        }
    }
}

@media only screen and (max-width: 575px) and (min-width: 560px) {
    .MainTitle {
        width: 95%;
        display: flex;
        flex-direction: column;
        margin: 0;

        &-title {
            font-size: 25px;
            width: 100% !important;
            text-align: center !important;
        }

        &-text {
            width: 100% !important;
            margin-bottom: 0;
        }
    }
}

@media only screen and (max-width: 700px) and (min-width: 575px) {
    .MainTitle {
        width: 85%;
        display: flex;
        flex-direction: column;
        margin: 20px 0 50px 0;

        &-title {
            font-size: 25px;
            width: 100% !important;
            text-align: start !important;
        }

        &-text {
            width: 100% !important;
        }
    }
}

@media only screen and (max-width: 768px) and (min-width: 700px) {
    .MainTitle {
        width: 85%;
        display: flex;
        flex-direction: column;
        margin: 20px 0 50px 0;

        &-title {
            font-size: 25px;
            width: 100% !important;
            text-align: center !important;
        }

        &-text {
            width: 100% !important;
        }
    }
}

@media only screen and (max-width: 800px) and (min-width: 768px) {
    .MainTitle {
        width: 80% !important;
        display: flex;
        flex-direction: column;
        margin: 20px 0 50px 0;
        text-align: center !important;

        &-title {
            font-size: 25px;
            width: 100% !important;
            text-align: center !important;
        }

        &-text {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 850px) and (min-width: 800px) {
    .MainTitle {
        width: 80%;
        display: flex;
        flex-direction: column;
        margin: 20px 0 50px 0 !important;

        &-title {
            font-size: 25px;
            width: 100% !important;
            text-align: start !important;
        }

        &-text {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 900px) and (min-width: 850px) {
    .MainTitle {
        width: 80%;
        display: flex;
        flex-direction: column;
        margin: 20px 0 !important;

        &-title {
            font-size: 25px;
            width: 100% !important;
            text-align: center !important;
        }

        &-text {
            width: 100% !important;
            font-size: 15px;
        }
    }
}

@media only screen and (max-width: 1000px) and (min-width: 900px) {
    .MainTitle {
        width: 80%;
        display: flex;
        flex-direction: column;
        margin: 20px 0 !important;  

        &-title {
            font-size: 25px;
            width: 100% !important;
            text-align: center !important;
        }

        &-text {
            text-align: center;
            width: 100% !important;
            font-size: 15px;
            margin-bottom: 20px !important;
        }
    }
}