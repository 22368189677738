@media only screen and (max-width: 400px) and (min-width: 300px) {
    .Reservation-box {
        height: 1300px !important;

        .Reservation {
            &-title {
                width: 90%;
                font-size: 40px;
            }

            &-btn {
                margin-top: -30px;
                margin-bottom: 40px;
            }
        }
    }
}


@media only screen and (max-width: 560px) and (min-width: 400px) {
    .Reservation-box {
        height: 1300px !important;

        .Reservation {
            &-title {
                width: 90%;
                font-size: 40px;
            }

            &-btn {
                margin-top: -30px;
                margin-bottom: 40px;
            }
        }
    }
}

@media only screen and (max-width: 575px) and (min-width: 560px) {
    .Reservation-box {
        height: 1250px !important;

        .Reservation {
            &-title {
                width: 90%;
                font-size: 40px;
            }

            &-btn {
                margin-top: -30px;
                margin-bottom: 40px !important;
            }
        }
    }
}

@media only screen and (max-width: 700px) and (min-width: 575px) {
    .Reservation-box {
        height: 1200px !important;

        .Reservation {
            &-title {
                width: 90%;
                font-size: 40px;
            }

            &-btn {
                margin-top: -30px;
                margin-bottom: 40px !important;
            }
        }
    }
}

@media only screen and (max-width: 768px) and (min-width: 700px) {
    .Reservation-box {
        height: 1200px !important;
    }
}

@media only screen and (max-width: 800px) and (min-width: 768px) {
    .Reservation-box {
        height: 1200px !important;
    }
}

@media only screen and (max-width: 850px) and (min-width: 800px) {}

@media only screen and (max-width: 900px) and (min-width: 850px) {}

@media only screen and (max-width: 1580px) and (min-width: 900px) {}