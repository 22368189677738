.Reservation-box {

    width: 100%;
    height: 800px;
    display: flex;
    // border: 1px solid red;


    .Reservation {
        width: 100%;
        height: 430px;
        text-align: center;
        background-color: #000;
        background-size: cover;
        padding: 100px 0;
        position: relative;
        // margin-bottom: 170px;
        // margin-top: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        // border: 1px solid red;

        &-title {
            font-size: 50px;
            font-weight: 700;
            line-height: 60px;
            color: #fff;
            margin: 30px 0 50px 0;
        }

        &-text {
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            color: #fff;
        }

        &-btn {
            font-size: 15px;
            font-weight: 600;
            line-height: 21px;
            letter-spacing: 0.5px;
            color: #135D66;
            padding: 15px 30px;
            background-color: #fff;
            border-radius: 25px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            transition: all ease-in-out 0.2s;

            svg {
                width: 25px;
                height: 25px;
                margin-left: 15px;
            }

            &:hover {
                transform: scale(1.15);
            }
        }
    }
}