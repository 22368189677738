@media only screen and (max-width: 400px) and (min-width: 300px) {
    .Prices {
        width: 100%;

        .MainTitle {
            display: flex;
            flex-direction: column;
            align-items: center;

            &-text {
                width: 85%;
                text-align: left;
                margin: 0;
            }
        }

        &-cards {
            width: 100%;
        }

        .swiper {
            padding-bottom: 50px;
            height: 510px;
            
            .swiper-wrapper {
                max-width: 100%;
                margin: 0 auto;
                
                .swiper-slide {
                    text-align: center;
                    max-width: 100% !important;
                    padding: 0 20px;
                    margin: 0 !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 560px) and (min-width: 400px) {
    .Prices {
        width: 100%;

        .MainTitle {
            display: flex;
            flex-direction: column;
            align-items: center;

            &-text {
                width: 85%;
                text-align: left;
                margin: 0;
            }
        }

        &-cards {
            width: 100%;
        }

        .swiper {
            padding-bottom: 50px;
            height: 510px;
            
            .swiper-wrapper {
                max-width: 100%;
                margin: 0 auto;
                
                .swiper-slide {
                    text-align: center;
                    max-width: 100% !important;
                    padding: 0 20px;
                    margin: 0 !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 575px) and (min-width: 560px) {
    .Prices {
        .MainTitle {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &-title {
                width: 100%;
                text-align: center !important;
                margin: 0;
            }

            &-text {
                width: 90% !important;
                text-align: center !important;
                margin: 0;
            }
        }
    }

    .swiper {
        padding-bottom: 50px;
        margin-bottom: 0;

        .swiper-wrapper {
            max-width: 100%;
            margin: 0 auto;

            .swiper-slide {
                text-align: center;
                max-width: 100% !important;
                padding: 0 20px;
                margin: 0 !important;
            }
        }
    }
}

@media only screen and (max-width: 700px) and (min-width: 575px) {
    .Prices {
        .MainTitle {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &-title {
                width: 100%;
                text-align: center !important;
                margin: 0;
            }

            &-text {
                width: 90% !important;
                text-align: center !important;
                margin: 0;
            }
        }
    }

    .swiper {
        .swiper-wrapper {
            margin-bottom: 0;
            height: 50%;

            .swiper-slide {
                text-align: center;
            }
        }
    }
}

@media only screen and (max-width: 768px) and (min-width: 700px) {
    .Prices {
        .MainTitle {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &-title {
                width: 100%;
                text-align: center !important;
                margin: 0;
            }

            &-text {
                width: 90% !important;
                text-align: center !important;
                margin: 0;
            }
        }
    }

    .swiper {
        .swiper-wrapper {
            .swiper-slide {
                text-align: center;
            }
        }
    }
}

@media only screen and (max-width: 800px) and (min-width: 768px) {
    .Prices {
        .MainTitle {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &-title {
                width: 100%;
                text-align: center !important;
                margin: 0;
            }

            &-text {
                width: 90% !important;
                text-align: center !important;
                margin: 0;
            }
        }
    }

    .swiper {
        .swiper-wrapper {
            .swiper-slide {
                text-align: center;
            }
        }
    }
}

@media only screen and (max-width: 850px) and (min-width: 800px) {
    .Prices {
        .MainTitle {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &-title {
                width: 100%;
                text-align: center !important;
                margin: 0;
            }

            &-text {
                width: 90% !important;
                text-align: center !important;
                margin: 0;
            }
        }
    }

    .swiper {
        .swiper-wrapper {
            .swiper-slide {
                text-align: center;
            }
        }
    }
}

@media only screen and (max-width: 900px) and (min-width: 850px) {
    .Prices {
        .MainTitle {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &-title {
                width: 100%;
                text-align: center !important;
                margin: 0;
            }

            &-text {
                width: 90% !important;
                text-align: center !important;
                margin: 0;
            }
        }
    }

    .swiper {
        .swiper-wrapper {
            .swiper-slide {
                text-align: center;
            }
        }
    }
}

@media only screen and (max-width: 1580px) and (min-width: 900px) {
    .Prices {
        .MainTitle {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &-title {
                width: 100%;
                text-align: center !important;
                margin: 0;
            }

            &-text {
                width: 100% !important;
                text-align: center !important;
                margin: 0;
            }
        }
    }

    .swiper {
        .swiper-wrapper {
            .swiper-slide {
                text-align: center;
            }
        }
    }
}