@media only screen and (max-width: 400px) and (min-width: 300px) {
    .FormCard {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (max-width: 560px) and (min-width: 400px) {
    .FormCard {
        width: 100%;
        display: flex;
        flex-direction: column;

        &-title {
            margin-left: 0 !important;
        }
    }
}

@media only screen and (max-width: 575px) and (min-width: 560px) {
    .FormCard {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (max-width: 700px) and (min-width: 575px) {
    .FormCard {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (max-width: 768px) and (min-width: 700px) {
    .FormCard {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (max-width: 800px) and (min-width: 768px) {
    .FormCard {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (max-width: 850px) and (min-width: 800px) {
    .FormCard {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (max-width: 900px) and (min-width: 850px) {
    .FormCard {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}

@media only screen and (max-width: 1580px) and (min-width: 900px) {
    .FormCard {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
}