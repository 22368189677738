@media only screen and (max-width: 400px) and (min-width: 300px) {
    .VisaSupport {
        width: 100%;

        .MainTitle {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &-title {
                width: 100%;
                text-align: center !important;
                margin: 0;
            }

            &-text {
                width: 90% !important;
                text-align: center !important;
                margin: 0;
            }
        }

        &-cards {
            width: 100%;
        }

        .swiper {
            height: 530px !important;
            padding: 0;

            .swiper-wrapper {
                max-width: 100%;
                margin: 0 auto;

                .swiper-slide {
                    text-align: center;
                    max-width: 100% !important;
                    padding: 0 20px;
                    margin: 0 !important;
                }
            }
        }

        .Card {
            width: 100%;
            min-height: 100% !important;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 20px !important;

            &-img {
                width: 70%;
                img {
                    height: 200px;
                    border-radius: 15px 15px 0 0;
                    object-fit: cover;
                }
            }

            &-body {
                border-radius: 0 0 15px 15px;
                right: 0;
                top: 0;
                padding: 25px 20px;
            }

            &-top {
                text-align: left;
                padding-bottom: 15px;
                margin-bottom: 15px;

                &-title {
                    font-size: 17px;
                    line-height: 17px;
                }

                &-users {
                    font-size: 12px;
                    line-height: 13px;
                }

                &-price {
                    &-value {
                        font-size: 17px;
                        line-height: 20px;
                        margin-bottom: 10px;
                    }

                    &-title {
                        font-size: 12px;
                        line-height: 15px;
                    }
                }
            }

            &-bottom {
                &-title {
                    font-size: 13px;
                    line-height: 17px;
                    margin-bottom: 10px;
                }

                &-items {
                    margin-bottom: 15px;
                }

                &-item {
                    font-size: 12px;
                    line-height: 17px;
                }

                .btn-default {
                    font-size: 13px;
                    padding: 10px 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 560px) and (min-width: 400px) {
    .VisaSupport {
        width: 100%;

        .MainTitle {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &-title {
                width: 100%;
                text-align: center !important;
                margin: 0;

            }

            &-text {
                width: 90% !important;
                text-align: center !important;
                margin: 0;

            }
        }

        &-cards {
            width: 100%;
        }

        .swiper {
            height: 530px !important;
            padding: 0;

            .swiper-wrapper {
                max-width: 100%;
                margin: 0 auto;

                .swiper-slide {
                    text-align: center;
                    max-width: 100% !important;
                    padding: 0 20px;
                    margin: 0 !important;
                }
            }
        }

        .Card {
            width: 100%;
            min-height: 100% !important;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 20px !important;

            &-img {
                width: 70%;
                img {
                    height: 200px;
                    border-radius: 15px 15px 0 0;
                    object-fit: cover;
                }
            }

            &-body {
                border-radius: 0 0 15px 15px;
                right: 0;
                top: 0;
                padding: 25px 20px;
            }

            &-top {
                text-align: left;
                padding-bottom: 15px;
                margin-bottom: 15px;

                &-title {
                    font-size: 17px;
                    line-height: 17px;
                }

                &-users {
                    font-size: 12px;
                    line-height: 13px;
                }

                &-price {
                    &-value {
                        font-size: 17px;
                        line-height: 20px;
                        margin-bottom: 10px;
                    }

                    &-title {
                        font-size: 12px;
                        line-height: 15px;
                    }
                }
            }

            &-bottom {
                &-title {
                    font-size: 13px;
                    line-height: 17px;
                    margin-bottom: 10px;
                }

                &-items {
                    margin-bottom: 15px;
                }

                &-item {
                    font-size: 12px;
                    line-height: 17px;
                }

                .btn-default {
                    font-size: 13px;
                    padding: 10px 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 575px) and (min-width: 560px) {
    .VisaSupport {
        .Card {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
    
            &-img {
                width: 70%;
                img {
                    height: 200px;
                    border-radius: 15px 15px 0 0;
                    object-fit: cover;
                }
            }
    
            &-body {
                border-radius: 0 0 15px 15px;
                // width: 70%;
                right: 0;
                top: 0;
                padding: 25px 20px;
            }
    
            &-top {
                text-align: left;
                padding-bottom: 15px;
                margin-bottom: 15px;
        
                &-title {
                    font-size: 17px;
                    line-height: 17px;
                }
        
                &-users {
                    font-size: 12px;
                    line-height: 13px;
                }
        
                &-price {
        
                    &-value {
                        font-size: 17px;
                        line-height: 20px;
                        margin-bottom: 10px;
                    }
        
                    &-title {
                        font-size: 12px;
                        line-height: 15px;
                    }
                }
            }
    
            &-bottom {
                
                &-title {
                    font-size: 13px;
                    line-height: 17px;
                    margin-bottom: 10px;
                }
        
                &-items {
                    margin-bottom: 15px;
                }
        
                &-item {
                    font-size: 12px;
                    line-height: 17px;
                }
    
                .btn-default {
                    font-size: 13px;
                    padding: 10px 0;
                }
            }
        }
        
        .swiper {
            height: 665px !important;
            padding: 0 !important;
            margin-bottom: 40px;

            .swiper-wrapper {
                max-width: 100%;
                margin: 0 auto;

                .swiper-slide {
                    text-align: center;
                    max-width: 100% !important;
                    padding: 0 20px;
                    margin: 0 !important;
                }
            }
        }
    }
}

@media only screen and (max-width: 700px) and (min-width: 575px) {
    .VisaSupport {
        .MainTitle {
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &-title {
                width: 100%;
                text-align: center !important;
                margin: 0;

            }

            &-text {
                width: 90% !important;
                text-align: center !important;
                margin: 0;

            }
        }
    }

    .swiper {
        height: 530px !important;
        padding: 0 !important;
        .swiper-wrapper {
            margin-bottom: 0;
            height: 50%;

            .swiper-slide {
                text-align: center;
            }
        }
    }
}

@media only screen and (max-width: 768px) and (min-width: 700px) {
    .VisaSupport {
        .MainTitle {
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &-title {
                width: 100%;
                text-align: center !important;
                margin: 0;

            }

            &-text {
                width: 90% !important;
                text-align: center !important;
                margin: 0;

            }
        }
    }

    .swiper {
        height: 580px !important;
        .swiper-wrapper {
            .swiper-slide {
                text-align: center;
            }
        }
    }
}

@media only screen and (max-width: 800px) and (min-width: 768px) {
    .VisaSupport {
        .MainTitle {
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &-title {
                width: 100%;
                text-align: center !important;
                margin: 0;

            }

            &-text {
                width: 90% !important;
                text-align: center !important;
                margin: 0;

            }
        }
    }


    .swiper {
        height: 650px !important;

        .swiper-wrapper {
            .swiper-slide {
                text-align: center;
            }
        }
    }
}

@media only screen and (max-width: 850px) and (min-width: 800px) {
    .VisaSupport {
        .MainTitle {
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &-title {
                width: 100%;
                text-align: center !important;
                margin: 0;

            }

            &-text {
                width: 90% !important;
                text-align: center !important;
                margin: 0;

            }
        }
    }

    .swiper {
        height: 600px !important;
        .swiper-wrapper {
            .swiper-slide {
                text-align: center;
            }
        }
    }
}

@media only screen and (max-width: 900px) and (min-width: 850px) {
    .VisaSupport {
        .MainTitle {
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &-title {
                width: 100%;
                text-align: center !important;
                margin: 0;

            }

            &-text {
                width: 90% !important;
                text-align: center !important;
                margin: 0;

            }
        }
    }

    .swiper {
        .swiper-wrapper {
            .swiper-slide {
                text-align: center;
            }
        }
    }
}

@media only screen and (max-width: 1580px) and (min-width: 900px) {
    .VisaSupport {
        .MainTitle {
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &-title {
                width: 100%;
                text-align: center !important;
                margin: 0;
            }

            &-text {
                width: 90% !important;
                text-align: center !important;
                margin: 0;

            }
        }
    }

    .swiper {
        .swiper-wrapper {
            .swiper-slide {
                text-align: center;
            }
        }
    }
}
