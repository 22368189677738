@media only screen and (max-width: 400px) and (min-width: 300px) {
    .Explore {
        display: flex;
        flex-direction: column;
        align-items: center;

        .MainTitle {
            width: 90%;
            display: flex;
            flex-direction: column;

            &-title {
                font-size: 25px;
                width: 100%;
                text-align: center !important;

            }

            &-text {
                width: 100% !important;
                font-size: 15px;
                text-align: center !important;
            }
        }
    }
}

@media only screen and (max-width: 560px) and (min-width: 400px) {
    .Explore {
        display: flex;
        flex-direction: column;
        align-items: center;

        .MainTitle {
            width: 90%;
            display: flex;
            flex-direction: column;

            &-title {
                font-size: 25px;
                width: 100%;
                text-align: center !important;

            }

            &-text {
                width: 100% !important;
                font-size: 15px;
            }
        }
    }
}

@media only screen and (max-width: 575px) and (min-width: 560px) {
    .Explore {
        display: flex;
        flex-direction: column;
        align-items: center;

        .MainTitle {
            width: 95%;
            display: flex;
            flex-direction: column;

            &-title {
                font-size: 25px;
                width: 100%;
                text-align: center !important;

            }

            &-text {
                width: 100% !important;
                font-size: 15px;
                text-align: center !important;
            }
        }
    }
}

@media only screen and (max-width: 700px) and (min-width: 575px) {
    .Explore {
        display: flex;
        flex-direction: column;
        align-items: center;

        .MainTitle {
            width: 85%;
            display: flex;
            flex-direction: column;

            &-title {
                font-size: 25px;
                width: 100%;
                text-align: center !important;

            }

            &-text {
                width: 100% !important;
                font-size: 15px;
                text-align: center !important;
            }
        }
    }
}

@media only screen and (max-width: 768px) and (min-width: 700px) {
    .Explore {
        display: flex;
        flex-direction: column;
        align-items: center;

        .MainTitle {
            width: 85%;
            display: flex;
            flex-direction: column;

            &-title {
                font-size: 25px;
                width: 100%;
                text-align: center !important;

            }

            &-text {
                width: 100% !important;
                font-size: 15px;
                text-align: center !important;
            }
        }
    }
}

@media only screen and (max-width: 800px) and (min-width: 768px) {
    .Explore {
        display: flex;
        flex-direction: column;
        align-items: center;

        .MainTitle {
            width: 80%;
            display: flex;
            flex-direction: column;

            &-title {
                font-size: 25px;
                width: 100%;
                text-align: center !important;

            }

            &-text {
                width: 100% !important;
                font-size: 15px;
                text-align: center !important;
            }
        }
    }
}

@media only screen and (max-width: 850px) and (min-width: 800px) {
    .Explore {
        display: flex;
        flex-direction: column;
        align-items: center;

        .MainTitle {
            width: 80%;
            display: flex;
            flex-direction: column;

            &-title {
                font-size: 25px;
                width: 100%;
                text-align: center;

            }

            &-text {
                width: 100% !important;
                font-size: 15px;
            }
        }
    }
}

@media only screen and (max-width: 900px) and (min-width: 850px) {
    .Explore {
        display: flex;
        flex-direction: column;
        align-items: center;

        .MainTitle {
            width: 80%;
            display: flex;
            flex-direction: column;

            &-title {
                font-size: 25px;
                width: 100% !important;
                text-align: center;

            }

            &-text {
                width: 100% !important;
                font-size: 15px;
            }
        }
    }
}

@media only screen and (max-width: 1000px) and (min-width: 900px) {
    .Explore {
        display: flex;
        flex-direction: column;
        align-items: center;

        .MainTitle {
            width: 80%;
            display: flex;
            flex-direction: column;

            &-title {
                font-size: 25px;
                width: 100%;
                text-align: center;

            }

            &-text {
                width: 50% !important;
                font-size: 15px;
            }
        }
    }
}