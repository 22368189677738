@media only screen and (max-width: 400px) and (min-width: 300px) {
    .FormSection {
        padding: 30px 0;

        &-inner {
            display: flex;
            flex-direction: column;
            height: auto;
            gap: 30px;
        }

        &-info {
            width: 90%;
        }

        &-map {
            width: 90%;
        }

        &-numberName {
            text-align: center;
            width: 95%;
            font-size: 11px;
        }

        &-number {
            text-align: center;
            width: 85% !important;
            font-size: 10px !important;
            margin-left: 0 !important;
        }

        &-call {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        &-callChild {
            width: 90%;
        }
    }
}

@media only screen and (max-width: 560px) and (min-width: 400px) {
    .FormSection {
        padding: 30px 0;

        &-inner {
            display: flex;
            flex-direction: column;
            height: auto;
            gap: 30px;
        }

        &-info {
            width: 90%;
        }

        &-map {
            width: 90%;
        }

        &-numberName {
            text-align: center;
            width: 95%;
            font-size: 11px;
        }

        &-number {
            text-align: center;
            width: 85% !important;
            font-size: 10px !important;
            margin-left: 0 !important;
        }

        &-call {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        &-callChild {
            width: 80%;
        }
    }
}

@media only screen and (max-width: 575px) and (min-width: 560px) {
    .FormSection {
        padding: 30px 0;

        &-inner {
            display: flex;
            flex-direction: column;
            height: auto;
            gap: 30px;
        }

        &-info {
            width: 90%;
        }

        &-map {
            width: 90%;
        }
    }
}

@media only screen and (max-width: 700px) and (min-width: 575px) {
    .FormSection {
        padding: 30px 0;

        &-inner {
            display: flex;
            flex-direction: column;
            height: auto;
            gap: 30px;
        }

        &-info {
            width: 90%;
        }

        &-map {
            width: 90%;
        }
    }
}

@media only screen and (max-width: 768px) and (min-width: 700px) {
    .FormSection {
        padding: 30px 0;

        &-inner {
            display: flex;
            flex-direction: column;
            height: auto;
            gap: 30px;
        }

        &-info {
            width: 90%;
        }

        &-map {
            width: 90%;
        }
    }
}

@media only screen and (max-width: 800px) and (min-width: 768px) {
    .FormSection {
        padding: 30px 0;

        &-inner {
            display: flex;
            flex-direction: column;
            height: auto;
            gap: 30px;
        }

        &-info {
            width: 90%;
        }

        &-map {
            width: 90%;
        }
    }
}

@media only screen and (max-width: 850px) and (min-width: 800px) {
    .FormSection {
        padding: 30px 0;

        &-inner {
            display: flex;
            flex-direction: column;
            height: auto;
            gap: 30px;
        }

        &-info {
            width: 90%;
        }

        &-map {
            width: 90%;
        }
    }
}

@media only screen and (max-width: 900px) and (min-width: 850px) {
    .FormSection {
        padding: 30px 0;

        &-inner {
            display: flex;
            flex-direction: column;
            height: auto;
            gap: 30px;
        }

        &-info {
            width: 90%;
        }

        &-map {
            width: 90%;
        }
    }
}

@media only screen and (max-width: 1500px) and (min-width: 900px) {
    .FormSection {
        padding: 30px 0;

        &-info {
            width: 39%;
        }

        &-map {
            width: 59%;
        }
    }
}