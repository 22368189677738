* {
    margin: 0;
    padding: 0;
}

ul {
    margin: 0;
    padding: 0;
}

li {
    list-style-type: none !important;
}

a {
    text-decoration: none;
}

body {
    font-family: 'Poppins', sans-serif;
    position: relative;
}

.btn-top {
    position: fixed;
    right: 30px;
    bottom: 40px;
    z-index: 9;
    padding: 15px;
    border-radius: 50%;
    border: none;
    background-color: #3B667D;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

    &:active {
        box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px inset, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px 3px 0px inset;
    }

    svg {
        font-size: 25px;
        color: #fff;
    }
}

.container {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    box-sizing: border-box;
    overflow: hidden;
}

.mini-container {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    box-sizing: border-box;
}

.btn-default {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    background-color: #3B667D;
    // background-color: #41718B;
    padding: 15px 20px;
    border-radius: 25px;
    text-align: center;
    border: none;
    outline: none;
    transition: all ease 0.4s;
    cursor: pointer;

    &:hover {
        background-color: #5992B1;
    }
}