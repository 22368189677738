.Card {
    width: 100%;
    height: 100%;

    &-img {
        position: relative !important;
        z-index: -1;

        img {
            width: 80%;
            height: 400px;
            border-bottom-left-radius: 50px;
            border-top-left-radius: 50px;
        }
    }

    &-body {
        position: relative !important;
        display: inline-block;
        border-radius: 20px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        background-color: #fff;
        padding: 35px 30px;

        top: -300px;
        right: -180px;
        z-index: 2;

        width: 85%;
    }

    &-top {
        display: flex;
        justify-content: space-between;
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-bottom: 2px solid #eeeeee;

        &-title {
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0.5px;
        }

        &-users {
            font-size: 15px;
            font-weight: 400;
            line-height: 18px;
            color: #afafaf;
            margin-left: 10px;
        }

        &-price {
            width: 30%;
            text-align: right;

            &-value {
                font-size: 20px;
                font-weight: 700;
                line-height: 24px;
                color: #5992B1;
                margin-bottom: 10px;
            }

            &-title {
                font-size: 15px;
                font-weight: 400;
                line-height: 18px;
                color: #afafaf;
            }
        }
    }

    &-bottom {
        text-align: left;

        &-title {
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            margin-bottom: 15px;
        }

        &-items {
            margin-bottom: 30px;
        }

        &-item {
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            color: #afafaf;
            margin-bottom: 10px;

            span {
                margin-left: 10px;
            }
        }

        .btn-default {
            display: block;
        }
    }
}