.ContactCard {
    width: 30%;
    height: 270px;
    padding: 30px 50px;
    border-radius: 20px;
    box-shadow: rgba(99, 99, 99, 0.8) 0px 2px 8px 0px;
    background-color: #fff;
    // transform: translateY(150px);

    i {
        background-color: #f0f0f0;
        width: 60px;
        height: 60px;
        display: inline-block;
        text-align: center;
        line-height: 60px;
        border-radius: 50px;
        color: #135D66;
        font-size: 20px;
        margin-bottom: 10px;

        svg {
            width: 25px;
            height: 25px;
        }
    }

    &-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        color: #2A2A2A;
        margin: 15px 0;
    }

    &-text {
        font-size: 15px;
        font-weight: 400;
        line-height: 22.5px;
        color: #135D66;
    }
}