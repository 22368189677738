@media only screen and (max-width: 400px) and (min-width: 300px) {
    .ImageSlider {
        width: 90% !important;
        gap: 10px;

        &-slide {
            border-radius: 20px;
            height: 50vh !important;
        }

        .slide-title {
            font-size: 17px;
        }

        .slide-population {
            font-size: 12px;
        }
    }
}

@media only screen and (max-width: 560px) and (min-width: 400px) {
    .ImageSlider {
        width: 90% !important;
        gap: 10px;

        &-slide {
            border-radius: 20px;
            height: 50vh !important;
        }

        .slide-title {
            font-size: 17px;
        }

        .slide-population {
            font-size: 12px;
        }
    }
}

@media only screen and (max-width: 575px) and (min-width: 560px) {
    .ImageSlider {
        width: 90% !important;
        gap: 10px;

        &-slide {
            border-radius: 20px;
            height: 50vh !important;
        }

        .slide-title {
            font-size: 17px;
        }

        .slide-population {
            font-size: 12px;
        }
    }
}

@media only screen and (max-width: 700px) and (min-width: 575px) {
    .ImageSlider {
        width: 85% !important;
        gap: 10px;

        &-slide {
            border-radius: 20px;
            height: 50vh !important;
        }

        .slide-title {
            font-size: 17px;
        }

        .slide-population {
            font-size: 12px;
        }
    }
}

@media only screen and (max-width: 768px) and (min-width: 700px) {
    .ImageSlider {
        width: 85% !important;
        gap: 10px;

        &-slide {
            border-radius: 20px;
            height: 50vh !important;
        }

        .slide-title {
            font-size: 17px;
        }

        .slide-population {
            font-size: 12px;
        }
    }
}

@media only screen and (max-width: 800px) and (min-width: 768px) {
    .ImageSlider {
        width: 85% !important;
        gap: 10px;

        &-slide {
            border-radius: 20px;
            height: 50vh !important;
        }
    }
}

@media only screen and (max-width: 850px) and (min-width: 800px) {
    .ImageSlider {
        width: 85% !important;
        gap: 10px;

        &-slide {
            border-radius: 20px;
            height: 50vh !important;
        }
    }
}

@media only screen and (max-width: 900px) and (min-width: 850px) {
    .ImageSlider {
        width: 85% !important;
        // gap: 15px;

        &-slide {
            border-radius: 20px;
            height: 50vh !important;
        }
    }
}

@media only screen and (max-width: 1580px) and (min-width: 900px) {
    .ImageSlider {
        width: 85% !important;

        &-slide {
            border-radius: 20px;
        }
    }
}