.Header {
    background-color: #3B667D;
    box-sizing: content-box;
    position: fixed;
    width: 100%;
    z-index: 100;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;

    .logo {
        width: 120px;
        margin: 0;
    }

    &-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
            width: 130px;
            margin-left: 50px;
        }
    }

    &-links {
        display: flex;
    }

    ul {
        margin: 0;
    }

    li {
        padding: 30px 0;
    }

    &-link {
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0.5px;
        line-height: 22.5px;
        color: #ffffff;
        padding: 30px 30px;
        position: relative;
        transition: all ease 0.4s;
        box-sizing: border-box;

        &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 1px;
            background-color: #fff;
            top: 25px;
            left: 30px;
            transition: all ease 0.4s;
        }

        &:hover::before {
            width: 30%;
        }
    }

    &-langs {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-lang {
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0.5px;
        line-height: 22.5px;
        color: #ffffff;
        padding: 20px 15px;
    }

    button {
        border: none;
        outline: none;
        background-color: transparent;
    }

    .btn-burger {
        width: 100%;
        display: none;
        text-align: right;

        svg {
            font-size: 30px;
            width: 35px;
            height: 35px;
        }
    }

    .active {
        display: block;
    }

    .overlay {
        position: absolute;
        z-index: 15;
        right: 0;
        left: 0;
        top: 100px;
        bottom: 0;
        margin: 0 auto;
        width: 80%;
        height: 480px;
        display: none;
        background-color: #ffffff;
        transition: all ease 0.3s;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
        border-radius: 20px;

        &.active {
            // width: 30%;
            // top: 20rem;
            display: block;
        }

        li {
            padding: 0;
        }

        &-menu {
            padding: 20px;
        }

        &-link {
            text-align: center;
            display: block;
            padding: 20px 25px;
            width: 100%;
            border-bottom: 1px solid #ccc;
            color: #3B667D;
            font-weight: 500;
            letter-spacing: 1.5px;
        }

        &-langs {
            width: 40%;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin-top: 30px;
        }

        a svg {
            color: #000;
            font-size: 23px;
            margin-top: 20px;
        }
        
        &-lang {
            font-weight: 600;
            padding: 20px;
        }

        .icons {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 27px;
                height: 27px;
            }
        }
    }
}