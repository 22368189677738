@media only screen and (max-width: 400px) and (min-width: 300px) {
    .HeroSection {
        width: 100%;

        &-inner {
            width: 100% !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        &-title {
            width: 100% !important;
            display: inline-block !important;
            font-size: 20px;
            padding-top: 35px;
        }


        &-cityName {
            font-size: 35px;
            margin: 10px 0 20px 0;
        }


        &-title {
            font-size: 15px;
        }
    }
}

@media only screen and (max-width: 575px) and (min-width: 560px) {
    .HeroSection {
        height: 100vh;

        &-title {
            padding-top: 20px;
            margin-bottom: 20px;
        }

        &-cityName {
            font-size: 38px;
            margin: 10px 0 20px 0;
        }

        &-title {
            font-size: 15px;
        }
    }
}

@media only screen and (max-width: 700px) and (min-width: 575px) {
    .HeroSection {
        height: 100vh;

        &-title {
            padding-top: 60px;
        }

        .carousel {
            width: 100%;
            display: inline-block !important;
            z-index: 1;
            &-cityName {
                font-size: 43px;
            }

            &-title {
                font-size: 18px;
            }
        }
    }
}
@media only screen and (max-width: 800px) and (min-width: 700px) {
    .HeroSection {
        height: 100vh;

        &-title {
            padding-top: 60px;
        }

        &-cityName {
            font-size: 43px;
        }

        &-title {
            font-size: 18px;
        }

        .btn-default {
            margin-bottom: 50px;
        }
    }
}
