.Visit {
    // margin-bottom: 100px !important;

    .MainTitle {
        &-title {
            text-align: center !important;
        }

        &-text {
            text-align: center !important;
            margin: 0;
        }
    }
}

@media only screen and (max-width: 560px) and (min-width: 400px) {
    .Visit {
        display: flex;
        flex-direction: column;
        align-items: center;

        .MainTitle {
            width: 100% !important;
            &-text {
                // width: 85%;
                text-align: center !important;
                margin-bottom: 20px !important;
            }
        }
    }
}

@media only screen and (max-width: 575px) and (min-width: 560px) {
    .Visit {
        display: flex;
        flex-direction: column;
        align-items: center;

        .MainTitle {
            text-align: center !important;
            &-text {
                width: 85% !important;
                text-align: center !important;
                margin-bottom: 20px !important;
            }
        }
    }
}

@media only screen and (max-width: 700px) and (min-width: 575px) {
    .Visit {
        display: flex;
        flex-direction: column;
        align-items: center;

        .MainTitle {
            &-text {
                width: 85%;
                text-align: left;
                margin: 0;
            }
        }
    }
}

@media only screen and (max-width: 768px) and (min-width: 700px) {
    .Visit {
        display: flex;
        flex-direction: column;
        align-items: center;

        .MainTitle {
            &-text {
                width: 85%;
                text-align: left;
                margin: 0;
            }
        }
    }
}

@media only screen and (max-width: 800px) and (min-width: 768px) {
    .Visit {
        display: flex;
        flex-direction: column;
        align-items: center;

        .MainTitle {
            text-align: center !important;

            &-text {
                width: 100% !important;
                text-align: center !important;
                margin-bottom: 20px;
            }
        }
    }
}

@media only screen and (max-width: 850px) and (min-width: 800px) {
    .Visit {
        display: flex;
        flex-direction: column;
        align-items: center;

        .MainTitle {
            text-align: center !important;

            &-text {
                width: 100% !important;
                text-align: center !important;
                margin-bottom: 20px;
            }
        }
    }
}

@media only screen and (max-width: 900px) and (min-width: 850px) {
    .Visit {
        display: flex;
        flex-direction: column;
        align-items: center;

        .MainTitle {
            text-align: center !important;
            
            &-text {
                width: 100% !important;
                text-align: center !important;
                margin-bottom: 20px !important;
            }
        }
    }
}

@media only screen and (max-width: 1580px) and (min-width: 900px) {
    .Visit {

        .MainTitle {
            &-title {
                text-align: left !important;
            }

            &-text {
                text-align: left !important;
                width: 60% !important;
                margin: 0;
            }
        }
    }
}