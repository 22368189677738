@media only screen and (max-width: 400px) and (min-width: 300px) {
    .Contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
}

@media only screen and (max-width: 560px) and (min-width: 400px) {
    .Contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
}

@media only screen and (max-width: 575px) and (min-width: 560px) {
    .Contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
}

@media only screen and (max-width: 700px) and (min-width: 575px) {
    .Contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
}

@media only screen and (max-width: 768px) and (min-width: 700px) {
    .Contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
}

@media only screen and (max-width: 800px) and (min-width: 768px) {
    .Contact {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
}

@media only screen and (max-width: 850px) and (min-width: 800px) {}

@media only screen and (max-width: 900px) and (min-width: 850px) {}

@media only screen and (max-width: 1580px) and (min-width: 900px) {}